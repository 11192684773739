/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/lines-between-class-members */
import { message } from 'antd';
import axios, { AxiosInstance } from 'axios';
import lodash from 'lodash';

import CONFIG from '@config/index';
import store from '@core/store/redux';
import { delay } from '@helper/functions';
import locale, { Locale } from '@locale/index';
import { RootState } from '@modules';
import { removeProfile, setToken } from '@modules/authentication/profileStore';

export interface IParamsHTTP<T> {
  method?: 'get' | 'post' | 'delete' | 'put';
  path: string;
  payload?: any;
  params?: any;
  config?: {
    responseType?: 'arraybuffer';
    isFormData?: boolean;
    signal?: AbortSignal;
  };
  showSuccess?: boolean;
  showError?: boolean;
  convert?: (res: any) => T;
}

export class HTTPRepository {
  private service: AxiosInstance;
  private token?: any;
  private refreshToken?: any;

  private language: keyof Locale = 'en';
  private refreshUrl: string = '/api/Users/RefreshToken';
  private refreshTokenController?: AbortController;
  // private isRefresh:

  constructor(baseURL?: string, refreshUrl?: string) {
    this.service = axios.create({
      baseURL: baseURL || CONFIG.API_BASE_URL,
      withCredentials: false,
    });
    if (refreshUrl != null) {
      this.refreshUrl = refreshUrl;
    }
    const state: RootState = store.getState();
    this.token = state?.profile?.token;
    this.refreshToken = state?.profile?.refreshToken;
    this.language = state.settingStore.language;

    store.subscribe(() => {
      const newState: RootState = store.getState();
      this.token = newState.profile.token;
      this.refreshToken = newState?.profile?.refreshToken;
      this.language = newState.settingStore.language;
    });
  }

  private handleSuccess(response: any, convert: any, showSuccess: any) {
    if (showSuccess) {
      message.success(locale[this.language][response?.data?.message] || response?.data?.message);
    }
    if (convert != undefined) {
      return Promise.resolve(convert(response.data?.data));
    }
    return Promise.resolve(response);
  }

  refreshTokenFunction = (): Promise<{ token: string; refreshToken: string }> => {
    this.refreshTokenController = new AbortController();
    return this.execute({
      path: this.refreshUrl,
      method: 'post',
      payload: { refreshToken: this.refreshToken },
      config: { signal: this.refreshTokenController.signal },
      showSuccess: false,
      convert: rs => {
        return {
          token: rs.accessToken,
          refreshToken: rs.refreshToken,
        };
      },
    });
  };
  private handleErrorResponse = (messageKey: string) => {
    const localizedMessage = locale[this.language][messageKey] || messageKey;
    message.error(localizedMessage);
  };

  private handle401Error = async (arr: any) => {
    if (this.token == null || this.refreshToken == null) {
      this.refreshTokenController = undefined;
      store.dispatch(removeProfile());
      window.localStorage.clear();
      window.location.href = CONFIG.LOGIN_PAGE;
      return;
    }

    if (this.refreshTokenController != null) {
      return delay(1000).then(() => this.execute(arr));
    }
    try {
      const rs: any = await this.refreshTokenFunction();
      this.refreshTokenController = undefined;
      store.dispatch(setToken(rs));
      return await this.execute(arr);
    } catch (e) {
      this.refreshTokenController = undefined;
      store.dispatch(removeProfile());
      window.localStorage.clear();
      window.location.href = CONFIG.LOGIN_PAGE;
      return Promise.reject(e);
    }
  };

  private handleError(error: any, showError: any, arr: any) {
    const status = error.response?.status;

    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    switch (status) {
      case 400: {
        if (showError) {
          if (error.response?.data?.message === 'Mes.Organization.NotEnough.Capacity') {
            console.error('fail');
          } else {
            this.handleErrorResponse(error.response?.data?.message);
          }
        }
        break;
      }
      case 401: {
        return this.handle401Error(arr);
      }
      case 500: {
        this.handleErrorResponse(error.response?.data?.message);
        break;
      }
      case undefined: {
        this.handleErrorResponse('server.networkError');
        break;
      }
      default: {
        if (showError) {
          this.handleErrorResponse(`HTTP CODE ${status}`);
        }
        break;
      }
    }

    return Promise.reject(error);
  }

  private preparePrivateHeaderConfig() {
    if (lodash.isEmpty(this.token)) {
      return {};
    }
    return {
      Authorization: `Bearer ${this.token}`,
      // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAwZGJkYzI3LTUyZDEtNGNlNy04Y2M0LWIzZWEwMWFhZWNiMCIsImlhdCI6MTY4OTM1MTYwNX0.vGj-ceBdKmuOGFRKMV8E0MiuS4SxHACW_Lscy939C4I',
    };
  }

  private getDefaultConfig({ isFormData }: any = {}) {
    const config = {
      headers: {},
    };

    const privateHeaderConfig = this.preparePrivateHeaderConfig();
    Object.assign(config.headers, privateHeaderConfig);

    if (isFormData) {
      Object.assign(config.headers, {
        'Content-Type': 'multipart/form-data',
      });
    }
    return config;
  }

  private isPrimitiveOrFile = (value: any): boolean => {
    return typeof value === 'number' || typeof value === 'string' || value instanceof File;
  };

  private buildUrlSearchParams = (_params: Record<string, any>): URLSearchParams => {
    return Object.keys(_params).reduce((url, key) => {
      const value = _params[key];
      if (Array.isArray(value)) {
        value.forEach(element => {
          if (element != undefined) {
            url.append(key, element);
          }
        });
      } else if (value != undefined) {
        url.append(key, value);
      }
      return url;
    }, new URLSearchParams());
  };

  private processData = (
    data: Record<string, any>,
    _formData: FormData,
    parentKey: string = '',
  ): void => {
    const processArrayItem = (
      item: any,
      formData: FormData,
      fieldName: string,
      index: number,
    ): void => {
      if (item == null) return;

      if (this.isPrimitiveOrFile(item)) {
        _formData.append(`${fieldName}[${index}]`, item);
      } else if (typeof item === 'object') {
        if (Array.isArray(item)) {
          item.forEach((nestedItem, nestedIndex) =>
            processArrayItem(nestedItem, formData, `${fieldName}[${index}]`, nestedIndex),
          );
        } else {
          this.processData(item, formData, `${fieldName}[${index}]`);
        }
      }
    };
    for (const key in data) {
      if (!data.hasOwnProperty(key)) continue;

      const value = data[key];
      const fieldName = parentKey ? `${parentKey}.${key}` : key;

      if (value == null) continue;

      if (Array.isArray(value)) {
        value.forEach((item, index) => processArrayItem(item, _formData, fieldName, index));
      } else if (this.isPrimitiveOrFile(value)) {
        _formData.append(fieldName, value);
      } else if (typeof value === 'object') {
        this.processData(value, _formData, fieldName);
      } else {
        _formData.append(fieldName, value);
      }
    }
  };

  execute<T>({
    method = 'get',
    path = '',
    payload,
    config = { responseType: undefined },
    params,
    showSuccess = true,
    showError = true,
    convert = res => res,
  }: IParamsHTTP<T>): Promise<T> {
    const { isFormData = false, responseType, signal } = config;

    let args: Array<any>;
    switch (method) {
      case 'get':
        args = [
          path,
          {
            ...this.getDefaultConfig(),
            responseType,
            signal,
            params: params ? this.buildUrlSearchParams(params) : undefined,
          },
        ];
        break;

      case 'delete':
        args = [
          path,
          {
            data: payload,
            ...this.getDefaultConfig(),
            signal,
            params: params ?? null,
          },
        ];
        break;

      case 'post':
      case 'put':
        const data = isFormData ? new FormData() : payload;
        if (isFormData) this.processData(payload, data);
        args = [path, data, { ...this.getDefaultConfig({ isFormData }), responseType, signal }];
        break;

      default:
        throw new Error(`Unsupported method: ${method}`);
    }

    // @ts-ignore
    return this.service[method](...args)
      .then(response => this.handleSuccess(response, convert, showSuccess))
      .catch(error =>
        this.handleError(error, showError, {
          method,
          path,
          payload,
          config,
          params,
          showSuccess,
          showError,
          convert,
        }),
      );
  }
}

const httpRepository = new HTTPRepository();

export default httpRepository;
